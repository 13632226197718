///
/// @file global/_media-queries.scss
///
/// \brief Named media queries for easy use in nested selectors
///
/// In general these should be used in a mobile-first fashion, meaning
/// everything is coded for $small, then overridden for $medium-up and
/// overridden once more for $large-up. So you shouldn't even have to reference
/// the $small-*, $*-down or $*-only breakpoints, but they're here for now
/// just cause.
///

// Primary breakpoint
// we use this for all nav and general template layout switches
$landscape-up: 1024px;
$portrait-up: 768px; // Portrait up

// Helper Queries
$portrait-only: ($portrait-up, $landscape-up); // Portrait to Before Landscape
$mobile-only: max-width ($portrait-up - 1); // Below portrait
$landscape-down: max-width ($landscape-up - 1); // Below landscape
$mobile-portrait: max-width 414px;
$iphone5-portrait: max-width 320px;
$iphoneX-portrait: max-width 375px;
$ipad-portrait: (min-device-width $portrait-up) (max-device-width $landscape-up) (orientation portrait);
$ipad-landscape: (min-device-width $landscape-up) (max-device-width 1366px) (orientation landscape);

// Mantle video styles
$mobile-landscape: 0 (max-height 480px) (orientation landscape) !default;

// old
$small-down: max-width 767px;
$small-only: (0, 767px);
$small-up: 0px;
$med-small-down: max-width 767px;
$med-small-only: (481px, 767px);
$med-small-up: 481px;
$medium-down: max-width 1024px;
$medium-only: (768px, 1024px);
$medium-up: 768px;
$large-up: 1025px;
$xlarge-up: 1200px; // custom breakpoint used to set max-width to site
$landscape-only: 1024px;
$landscape-up: 1024px;

//////// Unison.js stuff \\\\\\\\\
// create sass list to pass media query data
// if a breakpoint name changes, remember to
// update it in the list below as well
$mq-sync: small 0, portrait $portrait-up, landscape $landscape-up;
